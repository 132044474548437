<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container-fluid flix-form-group">
          <flixCheckPermissions role="holidays" permission="2" error>
            <holidays :key="update" @save="setUpdate" />
          </flixCheckPermissions>
        </div>
        <div class="flix-container">
          <flixCheckPermissions role="holidays" permission="2" error>
            <div class="flix-form-group">
              <holidayImporter @save="setUpdate"></holidayImporter>
            </div>
          </flixCheckPermissions>
        </div>
        <div class="flix-container">
            <flixCheckPermissions role="holidays" permission="2" error>
              <div class="flix-form-group">
                <holidayList :key="update" @save="setUpdate" />
              </div>
            </flixCheckPermissions>
          </div>
      </template>
    </flixDashboard>
  </div>
</template>
<script>
export default {
  components: {
    holidays () { return import('@/components/holidays/list') },
    holidayImporter () { return import('@/components/holidays/importer.vue') },
    holidayList () { return import('@/components/holidays/table.vue') }
  },
  data () {
    return {
      update: 1
    }
  },
  methods: {
    setUpdate () {
      this.update++
    }
  }
}
</script>
